<div
    id="legend-control"
    class="leaflet-bar leaflet-control"
    [ngClass]="{active: isAnyIndicatorPlotted, 'mobile-legend': showMobileUi}"
    (wheel)="onMouseWheel($event)"
    #legendPanel
>
    <button
        *ngIf="isAnyIndicatorPlotted && showMobileUi"
        type="button"
        class="btn pseudo-accordion inverse-chevron"
        (click)="isLegendCollapsed = !isLegendCollapsed"
        [attr.aria-expanded]="!isLegendCollapsed"
    >
        Légende
    </button>

    <div [(ngbCollapse)]="isLegendCollapsed">
        <ng-container *ngFor="let indicator of indicatorsPlot; let index = index">
            <div class="container-legend" *ngIf="indicator.legende">
                <ng-container *ngIf="isToolbarAvailable(indicator)">
                    <button
                        id="legend-indicator-label"
                        type="button"
                        class="btn"
                        [ngClass]="{'pseudo-accordion': !showMobileUi}"
                        (click)="!showMobileUi && collapse.toggle()"
                        [attr.aria-expanded]="!indicator.isCollapsed"
                    >
                        <span class="pe-1">
                            <span>
                                <span>{{ indicator.libelle_indic_short }}</span>
                                <span *ngIf="indicator.unit_short"
                                    >&nbsp;- {{ indicator.unit_short }}</span
                                >
                            </span>
                            <span>
                                <i
                                    class="fa fa-filter ms-3"
                                    *ngIf="indicator.isFiltered"
                                    ngbTooltip="Des filtres sont appliqués à cet indicateur"
                                    placement="bottom"
                                    tooltip-trigger="mouseenter"
                                    container="body"
                                ></i>
                                <i
                                    class="fa fa-bolt ms-3"
                                    *ngIf="indicator.isPrHypothesisApplied"
                                    ngbTooltip="Des hypothèses réseaux sont appliqués à cet indicateur"
                                    placement="bottom"
                                    tooltip-trigger="mouseenter"
                                    container="body"
                                ></i>
                            </span>
                        </span>
                    </button>

                    <div #collapse="ngbCollapse" [(ngbCollapse)]="indicator.isCollapsed">
                        <legend-toolbar [indicatorPlot]="indicator"></legend-toolbar>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isToolbarAvailable(indicator)">
                    <button
                        id="legend-indicator-label"
                        type="button"
                        class="btn"
                        [ngClass]="{'pseudo-accordion': !showMobileUi, 'static':!isToolbarAvailable(indicator) && !showMobileUi}"
                    >
                        <span class="pe-1">
                            <span>
                                <span>{{ indicator.libelle_indic_short }}</span>
                                <span *ngIf="indicator.unit_short"
                                    >&nbsp;- {{ indicator.unit_short }}</span
                                >
                            </span>
                            <span>
                                <i
                                    class="fa fa-filter ms-3"
                                    *ngIf="indicator.isFiltered"
                                    ngbTooltip="Des filtres sont appliqués à cet indicateur"
                                    placement="bottom"
                                    tooltip-trigger="mouseenter"
                                    container="body"
                                ></i>
                                <i
                                    class="fa fa-bolt ms-3"
                                    *ngIf="indicator.isPrHypothesisApplied"
                                    ngbTooltip="Des hypothèses réseaux sont appliqués à cet indicateur"
                                    placement="bottom"
                                    tooltip-trigger="mouseenter"
                                    container="body"
                                ></i>
                            </span>
                        </span>
                    </button>
                </ng-container>

                <div class="container-fluid py-2">
                    <ng-container *ngIf="['poly', 'poly3D', 'chart'].includes(indicator.form)">
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngFor="let legend of indicator.legende"
                            (click)="openLegendModal(indicator, legend)"
                        >
                            <ng-container *ngIf="indicator.type == 'valeur' || legend.exist">
                                <div
                                    class="col-3 pe-0 d-flex align-items-center justify-content-center"
                                    *ngIf="legend.color !== 'no'"
                                >
                                    <span
                                        class="flatInput"
                                        [ngStyle]="{
                    'opacity': legend.default_fillopacity * indicator.indicatorfillopacity,
                    'background-color': legend.color,
                    'border':  indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor
                  }"
                                    >
                                    </span>
                                </div>
                                <div class="col-9">
                                    <div
                                        [innerHtml]="legend.lib ? legend.lib : indicator.text_null"
                                    ></div>
                                </div>
                            </ng-container>
                        </div>
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.separate_zero_in_lgd == 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    class="flatInput"
                                    [ngStyle]="{
                  'opacity': indicator.indicatorfillopacity,
                  'background-color': indicator.couleur_zero_in_lgd,
                  'border':  indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor
                  }"
                                >
                                </span>
                            </div>
                            <div class="col-9">0</div>
                        </div>
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.hide_if_null !== 1 && indicator.zero_if_null_dyn !== 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    class="flatInput"
                                    [ngStyle]="{
                  'opacity': indicator.indicatorfillopacity,
                  'background-color': indicator.unavailableColor,
                  'border':  indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor
                }"
                                >
                                </span>
                            </div>
                            <div class="col-9">{{ indicator.text_null || 'Non disponible' }}</div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="['point', 'point_poste_10', 'point_poste_20', 'heatmap'].includes(indicator.form)"
                    >
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngFor="let legend of indicator.legende"
                            (click)="openLegendModal(indicator, legend)"
                        >
                            <ng-container *ngIf="indicator.type == 'valeur' || legend.exist">
                                <div
                                    class="col-3 pe-0 d-flex align-items-center justify-content-center"
                                >
                                    <span
                                        [ngStyle]="{
                    'opacity': legend.default_fillopacity * indicator.indicatorfillopacity,
                    'background-color': legend.color,
                    'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                    'border-radius': (2 * indicator.weightMultiplier * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                    'height': (2 * indicator.weightMultiplier * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                    'width': (2 * indicator.weightMultiplier * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px'
                  }"
                                    >
                                    </span>
                                </div>
                                <div class="col-9">
                                    <div
                                        [innerHtml]="legend.lib ? legend.lib : indicator.text_null"
                                    ></div>
                                </div>
                            </ng-container>
                        </div>
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.separate_zero_in_lgd == 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    [ngStyle]="{
                  'opacity': indicator.indicatorfillopacity,
                  'background-color': indicator.couleur_zero_in_lgd,
                  'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                  'border-radius': (2 * indicator.weightMultiplier * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'height': (2 * indicator.weightMultiplier * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'width': (2 * indicator.weightMultiplier * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'flex-shrink': 0
                }"
                                >
                                </span>
                            </div>
                            <div class="col-9">0</div>
                        </div>
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.hide_if_null !== 1 && indicator.zero_if_null_dyn !== 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    [ngStyle]="{
                  'opacity': indicator.indicatorfillopacity,
                  'background-color': indicator.unavailableColor,
                  'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                  'border-radius': (2 * indicator.weightMultiplier * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'height': (2 * indicator.weightMultiplier * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'width': (2 * indicator.weightMultiplier * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'flex-shrink': 0
                }"
                                >
                                </span>
                            </div>
                            <div class="col-9">{{ indicator.text_null || 'Non disponible' }}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="indicator.form == 'cluster'">
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngFor="let legend of indicator.legende"
                            (click)="openLegendModal(indicator, legend)"
                        >
                            <ng-container *ngIf="indicator.type == 'valeur' || legend.exist">
                                <div
                                    class="col-3 pe-0 d-flex align-items-center justify-content-center"
                                >
                                    <span
                                        [ngStyle]="{
                    'opacity': legend.default_fillopacity * indicator.indicatorfillopacity,
                    'background-color': legend.color,
                    'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                    'border-radius': (2 * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                    'height': (2 * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                    'width': (2 * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                    'flex-shrink': 0
                   }"
                                    >
                                    </span>
                                </div>
                                <div class="col-9">
                                    <div
                                        [innerHtml]="legend.lib ? legend.lib : indicator.text_null"
                                    ></div>
                                </div>
                            </ng-container>
                        </div>

                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.separate_zero_in_lgd == 1"
                            (click)="openLegendModal(indicator, legend)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    [ngStyle]="{
                  'opacity': indicator.indicatorfillopacity,
                  'background-color': indicator.couleur_zero_in_lgd,
                  'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                  'border-radius': (2 * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'height': (2 * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'width': (2 * legend.default_radius_weight * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'flex-shrink': 0
                }"
                                >
                                </span>
                            </div>
                            <div class="col-9">0</div>
                        </div>

                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.hide_if_null !== 1 && indicator.zero_if_null_dyn !== 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    [ngStyle]="{
                  'opacity': indicator.indicatorfillopacity,
                  'background-color': indicator.unavailableColor,
                  'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                  'border-radius': (2 * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'height': (2 * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'width': (2 * indicator.default_radius_weight + indicator.indicatorcontourweight) + 'px',
                  'flex-shrink': 0
                }"
                                >
                                </span>
                            </div>
                            <div class="col-9">{{ indicator.text_null || 'Non disponible' }}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="indicator.form == 'point_proportional'">
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngFor="let legend of indicator.legende"
                            (click)="openLegendModal(indicator, legend)"
                        >
                            <ng-container *ngIf="indicator.type == 'valeur' || legend.exist">
                                <div
                                    class="col-3 pe-0 px-2 d-flex align-items-center justify-content-center"
                                >
                                    <span
                                        [ngStyle]="{
                    'opacity': legend.default_fillopacity * indicator.indicatorfillopacity,
                    'background-color': legend.color,
                    'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                    'border-radius': round(legend.default_radius_weight * 2) + 'px',
                    'height': round(legend.default_radius_weight * 2) + 'px',
                    'width': round(legend.default_radius_weight * 2) + 'px'
                  }"
                                    >
                                    </span>
                                </div>
                                <div class="col-9">
                                    <div
                                        [innerHtml]="legend.lib ? legend.lib : indicator.text_null"
                                    ></div>
                                </div>
                            </ng-container>
                        </div>

                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.separate_zero_in_lgd == 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 px-2 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    [ngStyle]="{
                'opacity': indicator.indicatorfillopacity,
                'background-color': indicator.couleur_zero_in_lgd,
                'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                'border-radius': round(indicator.constant_weight * indicator.default_radius_weight * 2) + 'px',
                'height': round(indicator.constant_weight * indicator.default_radius_weight * 2) + 'px',
                'width': round(indicator.constant_weight * indicator.default_radius_weight * 2) + 'px'
                }"
                                >
                                </span>
                            </div>
                            <div class="col-9">0</div>
                        </div>

                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.hide_if_null !== 1 && indicator.zero_if_null_dyn !== 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 px-2 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    [ngStyle]="{
                  'opacity': indicator.indicatorfillopacity,
                  'background-color': indicator.unavailableColor,
                  'border': indicator.indicatorcontourweight + 'px solid ' + indicator.indicatorcontourcolor,
                  'border-radius': round(indicator.constant_weight * indicator.default_radius_weight * 2) + 'px',
                  'height': round(indicator.constant_weight * indicator.default_radius_weight * 2) + 'px',
                  'width': round(indicator.constant_weight * indicator.default_radius_weight * 2) + 'px'
                }"
                                >
                                </span>
                            </div>
                            <div class="col-9">{{ indicator.text_null || 'Non disponible' }}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="['line', 'migration'].includes(indicator.form)">
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngFor="let legend of indicator.legende"
                            (click)="openLegendModal(indicator, legend)"
                        >
                            <ng-container *ngIf="indicator.type == 'valeur' || legend.exist">
                                <div
                                    class="col-3 pe-0 d-flex align-items-center justify-content-center"
                                >
                                    <span
                                        class="d-flex"
                                        [ngStyle]="{
                      'opacity': legend.default_fillopacity * indicator.indicatorfillopacity,
                      'height': round(legend.default_radius_weight * indicator.default_radius_weight) + 'px'
                    }"
                                    >
                                        <span
                                            *ngFor="let width of createDashArray(legend, indicator); let idx = index"
                                            [ngStyle]="{
                      'opacity': idx % 2 === 0 ? 1 : 0,
                      'background-color': legend.color,
                      'border-radius': '20%',
                      'width': width + 'px'
                    }"
                                        >
                                        </span>
                                    </span>
                                </div>
                                <div class="col-9">
                                    <div
                                        [innerHtml]="legend.lib ? legend.lib : indicator.text_null"
                                    ></div>
                                </div>
                            </ng-container>
                        </div>

                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.separate_zero_in_lgd == 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    class="d-flex"
                                    [ngStyle]="{
                    'opacity': indicator.indicatorfillopacity,
                    'height': indicator.default_radius_weight + 'px'
                  }"
                                >
                                    <span
                                        *ngFor="let width of createDashArray(null, indicator); let idx = index"
                                        [ngStyle]="{
                    'opacity': idx % 2 === 0 ? 1 : 0,
                    'background-color': indicator.couleur_zero_in_lgd,
                    'border-radius': '20%',
                    'width': width + 'px'
                  }"
                                    >
                                    </span>
                                </span>
                            </div>
                            <div class="col-9">0</div>
                        </div>

                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngIf="indicator.hide_if_null !== 1 && indicator.zero_if_null_dyn !== 1"
                            (click)="openLegendModal(indicator)"
                        >
                            <div
                                class="col-3 pe-0 d-flex align-items-center justify-content-center"
                            >
                                <span
                                    class="d-flex"
                                    [ngStyle]="{
                    'opacity': indicator.indicatorfillopacity,
                    'height': indicator.default_radius_weight + 'px'
                  }"
                                >
                                    <span
                                        *ngFor="let width of createDashArray(null, indicator); let idx = index "
                                        [ngStyle]="{
                    'opacity': idx % 2 === 0 ? 1 : 0,
                    'background-color': indicator.unavailableColor,
                    'border-radius': '20%',
                    'width': width + 'px'
                  }"
                                    >
                                    </span>
                                </span>
                            </div>
                            <div class="col-9">{{ indicator.text_null || 'Non disponible' }}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="indicator.form === 'icon'">
                        <div
                            class="row d-flex align-items-center container-indicator"
                            *ngFor="let legend of indicator.legende"
                            (click)="openLegendModal(indicator, legend)"
                        >
                            <ng-container *ngIf="indicator.type == 'valeur' || legend.exist">
                                <div
                                    class="col-3 pe-0 d-flex align-items-center justify-content-center"
                                >
                                    <i
                                        class="fa fa-{{ legend.default_icon || indicator.default_icon }}"
                                        [ngStyle]="{
                    'color': legend.color,
                    'font-size': round(5 * legend.default_radius_weight * indicator.default_radius_weight) + 'px',
                    'opacity': legend.default_fillopacity * indicator.indicatorfillopacity,
                    'text-shadow': -indicator.indicatorcontourweight + 'px 0 ' + indicator.indicatorcontourcolor + ', 0 ' + indicator.indicatorcontourweight + 'px ' + indicator.indicatorcontourcolor +', ' + indicator.indicatorcontourweight + 'px 0 ' + indicator.indicatorcontourcolor + ', 0 -' + indicator.indicatorcontourweight + 'px ' + indicator.indicatorcontourcolor
                  }"
                                    >
                                    </i>
                                </div>
                                <div class="col-9">
                                    <div
                                        [innerHtml]="legend.lib ? legend.lib : indicator.text_null"
                                    ></div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <hr class="split split my-2" *ngIf="index != indicatorsPlot.length - 1" />
            </div>
        </ng-container>
    </div>
</div>
