import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, of, Subscription } from 'rxjs';
import { debounceTime, tap, map, switchMap, catchError } from 'rxjs/operators';
import { Control, DomEvent, DomUtil, Map } from 'leaflet';
import { ToastrService } from 'ngx-toastr';

import { Territory } from 'src/app/models/TerritoryTypes';

import { EventService } from 'src/app/services/event.service';
import { MapService } from 'src/app/services/map.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { TerService } from 'src/app/services/TerService';

@UntilDestroy()
@Component({
    selector: 'load-territories',
    templateUrl: './load-territories.template.html',
    styleUrls: ['./load-territories.component.scss'],
})
export class LoadTerritoriesComponent implements OnInit, OnDestroy {
    public loadTerritoriesControl: Control;
    public isShown: boolean = false;
    public btnLabel: string = '';
    private territory: Territory;
    private debounceTime: number = 1000;

    private subscription: Subscription;

    constructor(
        private notification: ToastrService,
        @Inject(EventService) private eventService: EventService,
        @Inject(MapService) private mapService: MapService,
        @Inject(TerService) private terService: TerService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
    ) {}

    ngOnInit(): void {
        this.mapService.map$.pipe(untilDestroyed(this)).subscribe((map) => {
            if (map) {
                this._initControl();
            }
        });

        this.eventService.indicatorPloted.pipe(untilDestroyed(this)).subscribe(() => {
            this.isShown = false;
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.setMoveEndSubscription();
        });

        this.eventService.indicatorUnploted.pipe(untilDestroyed(this)).subscribe(() => {
            const isAnyIndicatorDefined =
                Object.keys(this.plotIndicatorService.plotedIndicators).length > 0;
            if (!isAnyIndicatorDefined) {
                this.isShown = false;
                if (this.subscription) {
                    this.subscription.unsubscribe();
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private _initControl() {
        const LoadTerritoriesControl = Control.extend({
            onAdd(map: Map) {
                const element = DomUtil.get('load-territories-control');
                DomEvent.disableClickPropagation(element);
                return element;
            },
            onRemove(map: Map) {},
        });
        this.loadTerritoriesControl = new LoadTerritoriesControl({
            position: 'topleft',
        });
        this.loadTerritoriesControl.addTo(this.mapService.map);
    }

    public setMoveEndSubscription() {
        this.subscription = fromEvent(this.mapService.map, 'moveend')
            .pipe(
                tap(() => (this.isShown = false)),
                debounceTime(this.debounceTime),
                map(() => this._showButton()),
                switchMap(async (shouldShow: boolean) => {
                    if (shouldShow) {
                        await this._getLabel();
                    } else {
                        return of(null);
                    }
                }),
                catchError((error) => {
                    console.error('Error during map moveend processing', error);
                    this.notification.error('Une erreur est survenue.');
                    this.isShown = false;
                    return of(null);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private _showButton(): boolean {
        const typeId = this.terService.territoryScale.typeId;
        const territoryScaleLayer = this.terService.territoryScaleLayers[typeId];

        const mapCenter = this.mapService.getCenter();
        const isMapCenterWithinTerritoryScale = this.mapService.checkIfPointIsWithinPolygon(
            mapCenter,
            territoryScaleLayer,
        );
        return !isMapCenterWithinTerritoryScale;
    }

    private async _getLabel(): Promise<void> {
        this.isShown = false;

        const mapCenter = this.mapService.getCenter();
        const territorryByLocation = await this.terService.getGetTerritorryLabelByLocation(
            mapCenter.lat,
            mapCenter.lng,
        );
        const status = territorryByLocation.status;
        if (status) {
            const territory = territorryByLocation.territory;
            const isAlreadyDefined = this.terService.territories.some((t) => t.id == territory.id);
            if (!isAlreadyDefined) {
                this.territory = territory;
                this.btnLabel = `Charger les données de ${this.territory.label}`;
                this.isShown = true;
            }
        }
    }

    public async updateTerritories(): Promise<void> {
        try {
            this.isShown = false;
            this.terService.territories.push(this.territory);
            await this.terService.updateTerritoryLayer();
            this.eventService.emit('selectionTerritoryUpdated', false);
        } catch (error) {
            this.notification.error(
                'Une erreur est survenue. Impossible de charger le nouveau territoire',
            );
            this._showButton();
        }
    }
}
