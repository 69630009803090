import { Inject, Injectable } from '@angular/core';

import { Indicator } from 'src/app/models/Indicator';

import { UsefulService } from 'src/app/services/UsefulService';

@Injectable({
    providedIn: 'root',
})
export class ManualDistribution {
    public classCountsAvailable = [2, 3, 4, 5, 6, 7, 8, 9];

    public id = 'manuel';
    public label = 'Seuils manuels';

    constructor(@Inject(UsefulService) private usefulService: UsefulService) {}

    setClassCount(indicatorPlot: Indicator) {
        if (indicatorPlot.form == 'chart') {
            indicatorPlot.classCount = Object.keys(JSON.parse(indicatorPlot.chart_divider)).length;
        }

        indicatorPlot.classCountsAvailable = this.classCountsAvailable;
    }

    setScale(indicatorPlot: Indicator) {
        indicatorPlot.valueScale = indicatorPlot.getLegendIndex;
    }

    setLegendBoundaries(indicatorPlot: Indicator) {
        const legendBoundaries = indicatorPlot.legendBoundaries;
        return legendBoundaries;
    }

    convertTableBornesToLegendBoundaries(tableBornes: number[], decimalCount: number) {
        const step = 1 / Math.pow(10, decimalCount);
        tableBornes[0] = -Infinity;
        tableBornes[tableBornes.length - 1] = Infinity;

        const legendBoundaries = tableBornes
            .slice(0, -1)
            .reduce((legendBoundaries: number[][], value: number, index: number) => {
                const boundaryInf = value;

                const valueSup = tableBornes[index + 1] - step;
                const boundarySup = this.usefulService.round(valueSup, decimalCount);

                legendBoundaries.push([boundaryInf, boundarySup]);
                return legendBoundaries;
            }, []);

        legendBoundaries[0][0] = -Infinity;
        legendBoundaries[legendBoundaries.length - 1][1] = Infinity;
        return legendBoundaries;
    }

    convertLegendBoundariesToTableBornes(legendBoundaries: number[][]) {
        const tableBornes = legendBoundaries.map((boundary: number[]) => boundary[0]);
        tableBornes.push(legendBoundaries[legendBoundaries.length - 1][1]);
        return tableBornes;
    }

    checkIfLegendValid(legendBoundaries: number[][]) {
        const boundaries = JSON.parse(JSON.stringify(legendBoundaries));

        // Flatten the 2D array into a 1D array
        const flattened = boundaries.flat();
        flattened[0] = -Infinity;
        flattened[flattened.length - 1] = Infinity;

        // Check each element against all previous elements
        for (let i = 1; i < flattened.length; i++) {
            for (let j = 0; j < i; j++) {
                if (flattened[i] < flattened[j]) {
                    return false;
                }
            }
        }
        return true;
    }

    setLegendLabel(
        i: number,
        legendBoundaries: number[][],
    ): { label: string; min: number; max: number } {
        let label: string;
        let min: number;
        let max: number;

        if (i == 0) {
            min = -Infinity;
            max = legendBoundaries[i][1];
            const limitInf = this.usefulService.stringifyNumber(legendBoundaries[i + 1][0]);
            label = `< ${limitInf}`;
        } else if (i == legendBoundaries.length - 1) {
            min = legendBoundaries[i][0];
            max = Infinity;
            const limitSup = this.usefulService.stringifyNumber(legendBoundaries[i][0]);
            label = `<span class="ft-math">\u2265</span> ${limitSup}`;
        } else {
            min = legendBoundaries[i][0];
            max = legendBoundaries[i][1];
            const minStringified = this.usefulService.stringifyNumber(min);
            const maxStringified = this.usefulService.stringifyNumber(max);
            if (min == max) {
                label = minStringified;
            } else {
                label = minStringified + ' à ' + maxStringified;
            }
        }
        return { label, min, max };
    }
}
