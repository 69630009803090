<stTab class="filters" label="Filtres" [visible]="moduleService.isModuleActive.filters">
    <div class="d-flex flex-column h-100 filter-container">
        <div ngbDropdown class="m-4" *ngIf="selectedIndicator">
            <button ngbDropdownToggle type="button" class="btn btn-st-default w-100 text-wrap">
                {{ selectedIndicator.libelle_indic_complet }}
            </button>
            <div ngbDropdownMenu class="w-100 text-center">
                <ng-container *ngFor="let indicator of indicatorsData">
                    <button ngbDropdownItem (click)="changeSelectedIndicator(indicator)">
                        <small> {{ indicator.libelle_indic_complet }} </small>
                    </button>
                </ng-container>
            </div>
        </div>

        <div class="text-center text-st-active p-5" *ngIf="isLoading">
            <i class="fa fa-4x fa-spinner fa-spin"></i>
        </div>

        <ng-container
            *ngIf="selectedIndicator && filtersByIndicator[selectedIndicator.id_indicateur] && !!infoByIndicatorId[selectedIndicator.id_indicateur] && !isLoading"
        >
            <div class="container-fluid mx-0 p-4">
                <div class="d-flex text-st-secondary st-fw-bold">
                    <div class="flex-grow-1 me-5">
                        <div>
                            {{ infoByIndicatorId[selectedIndicator.id_indicateur].aggregationLabel
                            }}
                        </div>
                        <div>
                            {{ infoByIndicatorId[selectedIndicator.id_indicateur].countLabel }}
                        </div>
                    </div>

                    <div class="d-flex align-items-center" (click)="exportCsv()">
                        <span
                            class="fa fa-download fa-2x cursor-pointer"
                            ngbTooltip="Export des données"
                            placement="start"
                            container="body"
                        >
                        </span>
                    </div>
                </div>
            </div>

            <hr class="split mx-4" />

            <div class="container-fluid mx-0 flex-grow-1 p-4 criteria-section">
                <div
                    class="mb-3"
                    *ngFor="let category of filtersByIndicator[selectedIndicator.id_indicateur] | sortBy : 'id'"
                >
                    <singleFilter
                        [indicatorData]="selectedIndicator"
                        [category]="category"
                        [unit]="unit"
                    ></singleFilter>
                </div>
            </div>

            <hr class="split mx-4" />

            <div class="container-fluid mx-0 p-4 footer-filter">
                <div class="row">
                    <div class="col-6">
                        <stButton
                            btnLabel="Réinitialiser"
                            btnClass="btn-st-default w-100"
                            (click)="reinitAllFilters()"
                        >
                        </stButton>
                    </div>
                    <div class="col-6">
                        <stButton
                            btnLabel="Valider les filtres"
                            btnClass="btn-st-primary w-100"
                            (click)="validate()"
                        >
                        </stButton>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</stTab>
