('use strict');
import { Inject, Injectable } from '@angular/core';

import { Indicator } from 'src/app/models/Indicator';
import { Right, Scenario } from 'src/app/models/Scenarios';
import { Theme } from 'src/app/models/Theme';

import { ColorService } from 'src/app/services/ColorService';
import { DataService } from 'src/app/services/DataService';
import { EventService } from 'src/app/services/event.service';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { LoaderService } from 'src/app/services/LoaderService';
import { ManualDistribution } from 'src/app/services/plotIndicator/distributions/methods/Manual';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { ProsperReseauxService } from 'src/app/services/prosper-reseaux/prosper-reseaux.service';
import { ProsperReseauxClearService } from 'src/app/services/prosper-reseaux/prosper-reseaux-clear.service';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';

@Injectable({
    providedIn: 'root',
})
export class ScenariosService {
    public rights: Right[] = [
        {
            id: 1,
            label: 'Accès privé',
        },
        {
            id: 2,
            label: 'Accès public en lecture',
        },
        {
            id: 3,
            label: 'Accès public en lecture / écriture',
        },
    ];

    public currentScenario: Scenario = {
        id: -1,
        name: '',
        rightId: 1,
        creatorUserId: null,
        isLoading: false,
        isLoaded: false,
        raster: null,
        territory: null,
        indicators: null,
        filters: null,
        plugins: null,
    };

    public scenarios: any[];

    constructor(
        @Inject(ColorService) private colorService: ColorService,
        @Inject(DataService) private dataService: DataService,
        @Inject(EventService) private eventService: EventService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(ManualDistribution) private manualDistribution: ManualDistribution,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(ProsperReseauxService) private prService: ProsperReseauxService,
        @Inject(ProsperReseauxClearService) private prClearService: ProsperReseauxClearService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {
        this.clear();
    }

    clear() {
        this.currentScenario = {
            id: -1,
            name: '',
            rightId: 1,
            creatorUserId: null,
            isLoading: false,
            isLoaded: false,
            raster: null,
            territory: null,
            indicators: null,
            filters: null,
            plugins: null,
        };
    }

    setCurrentScenario(currentScenario: Scenario) {
        this.currentScenario = currentScenario;
    }

    async create(data: Scenario) {
        try {
            const scenario = await this.restService.createScenario(data);
            this.currentScenario.id = scenario.id;
            this.currentScenario.name = scenario.name;
            this.currentScenario.isLoaded = true;
            this.scenarios = await this.restService.getScenarios();
            return this.scenarios;
        } catch (error) {
            console.error('Error create', error);
            throw error;
        }
    }

    async replace(scenarioId: number, data: Scenario) {
        try {
            await this.restService.replaceScenario(scenarioId, data);
            this.scenarios = await this.restService.getScenarios();
            return this.scenarios;
        } catch (error) {
            console.error('Error replace', error);
            throw error;
        }
    }

    setDataToSave() {
        const data: Scenario = {
            raster: this.setRaster(),
            territory: this.setTerritory(),
            indicators: this.setIndicators(),
            filters: this.setFilters(),
            plugins: [],
        };

        // prosper reseaux special feature
        // check if any prosper reseaux indicator is ploted
        const plotedIndicators = Object.values(this.plotIndicatorService.plotedIndicators);
        const isAnyPrIndicator = plotedIndicators.some(
            (indicatorPlot: Indicator) => indicatorPlot.plugin == 'prosper_reseaux',
        );
        if (isAnyPrIndicator) {
            data.plugins.push(this.prService.getScenarioToSave());
        }

        return data;
    }

    setRaster() {
        return {
            rasterId: this.mapService.currentRaster.id,
        };
    }

    setTerritory() {
        return {
            scaleName: this.terService.territoryScale.type,
            scaleYear: this.terService.territoryScale.year,
            territoryIds: this.terService.territories.map((t) => t.id),
            boundaryScaleIds: this.terService.territoryScales
                .filter((territoryScale) => territoryScale.isPloted)
                .map((territoryScale) => territoryScale.id),
        };
    }

    setIndicators() {
        const indicators = [];
        for (let indicatorId in this.plotIndicatorService.plotedIndicators) {
            const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
            const granularity = indicatorPlot.granularity;

            const type = indicatorPlot.type;
            let distributionMethodId = 'class';
            if (type == 'valeur') {
                distributionMethodId = indicatorPlot.distribution.method.id;
                if (distributionMethodId === 'manuel') {
                    const legendBoundaries = indicatorPlot.legendBoundaries;
                    const tableBornes =
                        this.manualDistribution.convertLegendBoundariesToTableBornes(
                            legendBoundaries,
                        );
                    distributionMethodId = JSON.stringify(tableBornes);
                }
            }

            const indicatorInfo = {
                indicatorId: parseInt(indicatorId),
                granularityName: granularity ? granularity.type : null,
                granularityYear: granularity ? granularity.year : null,
                shape: indicatorPlot.form,
                classCount: indicatorPlot.classCount,
                distributionMethodId: distributionMethodId,
                colorId: indicatorPlot.degradeId,
                gradientColorName: indicatorPlot.degrade || null,
                opacity: indicatorPlot.indicatorfillopacity,
            };

            indicators.push(indicatorInfo);
        }
        return indicators;
    }

    setFilters() {
        const filters = [];
        for (let indicatorId in this.plotIndicatorService.plotedIndicators) {
            const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];

            if (indicatorPlot.static_dynamic == 'dyn') {
                const filtersInfo = this.filterDataService.createFilterForSave(
                    parseInt(indicatorId),
                );

                const filtersInfoConverted = filtersInfo.map((info) => ({
                    indicatorId: info.indicatorId,
                    criteriaId: info.id_critere,
                    determinantId: info.id_dter,
                }));

                filters.push(...filtersInfoConverted);
            }
        }
        return filters;
    }

    async partialUpdate(scenarioId: number, scenarioInfo: Scenario) {
        try {
            await this.restService.updateScenario(scenarioId, scenarioInfo);
            this.scenarios = await this.restService.getScenarios();
            if (this.currentScenario.id == scenarioId) {
                this.currentScenario.name = scenarioInfo.name;
                this.currentScenario.rightId = scenarioInfo.rightId;
            }
            return this.scenarios;
        } catch (error) {
            console.error('Error partialUpdate', error);
            throw error;
        }
    }

    async getScenarios() {
        try {
            this.scenarios = await this.restService.getScenarios();
            return this.scenarios;
        } catch (error) {
            console.error('Error loadScenarios', error);
            throw error;
        }
    }

    async getScenario(scenario: Scenario) {
        const scenarioId = scenario.id;
        const name = scenario.name;
        const rightId = scenario.rightId;

        const creatorId = this.scenarios.find(
            (scenario) => scenario.id == scenarioId,
        ).creatorUserId;

        this.currentScenario.isLoading = true;
        this.currentScenario.name = name;
        this.currentScenario.creatorUserId = creatorId;

        this.dataService.deleteAllIndicators();
        this.terService.clearTerritoryLayers();
        this.plotIndicatorService.clear();
        this.filterDataService.clearBase();

        try {
            const scenarioData = await this.restService.getScenario(scenarioId);
            scenarioData.name = name;
            scenarioData.rightId = rightId;
            const plugins = scenarioData.plugins;

            await this._loadScenario(scenarioData);

            if (plugins.length) {
                this._loadPlugins(plugins);
            } else {
                this.moduleService.closeAllModules();
            }

            const year = this.terService.territoryScale.year;
            const scaleTypeId = this.terService.territoryScale.typeId;
            const territoryIds = this.terService.territories.map((t) => t.id);
            await this.mapService.centerOnBbox(year, scaleTypeId, territoryIds);

            Object.assign(this.currentScenario, scenarioData);

            this.currentScenario.isLoading = false;
            this.currentScenario.isLoaded = true;

            // this.moduleService.territorySelectionModal.close();

            // this.eventService.emit('selectionTerritoryUpdated');
            this.eventService.emit('territoryPloted');
            this.eventService.emit('indicatorsPlotedFromScenario', this.currentScenario.indicators);

            this.loaderService.stop(scenarioId.toString());
        } catch (error) {
            console.error('Error getScenario', error);
            this.currentScenario.isLoading = false;
            this.currentScenario.isLoaded = false;

            this.dataService.deleteAllIndicators();
            this.terService.clearTerritoryLayers();
            this.plotIndicatorService.clear();
            this.filterDataService.clearBase();

            this.loaderService.stop(scenarioId.toString());
            throw error;
        }
    }

    private async _loadScenario(scenario: any) {
        this.updateRaster(scenario);

        try {
            const territoryPromises = this.updateTerritory(scenario);
            await Promise.all(territoryPromises);

            const indicatorsByTheme = await this.dataService.getIndicatorLibraryByTheme();
            await this.updateIndicators(scenario, indicatorsByTheme);
        } catch (error) {
            console.error('Error loadScenario', error);
            throw error;
        }
    }

    updateRaster = (scenario: any) => {
        if (scenario.raster) {
            this.mapService.updateRaster(scenario.raster.rasterId);
        }
    };

    updateTerritory = (scenario: any) => {
        const territoryScale = this.terService.territoryScales.find(
            (territoryScale) => territoryScale.type == scenario.territory.scaleName,
        );
        if (territoryScale) {
            this.terService.updateTerritoryScale(territoryScale);
        }

        const territories = scenario.territory.territoryIds.map((id: number) => ({
            id: id,
            label: '',
        }));
        this.terService.updateTerritories(territories);

        if (territoryScale && territories.length) {
            this.terService.updateTerritoryLabels();
        }

        this.terService.territoryScales.forEach(
            (territoryScale) => (territoryScale.isPloted = false),
        );
        const plotedTerritoryScaleIds = scenario.territory.boundaryScaleIds;
        this.terService.territoryScales
            .filter((territoryScale) => plotedTerritoryScaleIds.includes(territoryScale.id))
            .forEach((territoryScale) => (territoryScale.isPloted = true));

        const promises = [];
        plotedTerritoryScaleIds.forEach((scaleId: number) => {
            const territoryScale = this.terService.territoryScales.find(
                (territoryScale) => territoryScale.id == scaleId,
            );
            if (territoryScale) {
                promises.push(this.terService.addTerritoryLayer(territoryScale));
            }
        });
        return promises;
    };

    updateIndicators = (scenario: any, indicatorsByTheme: any) => {
        const promises = scenario.indicators.map((indicator: any) =>
            this._setPlotIndicatorPromise(indicator, indicatorsByTheme),
        );
        return Promise.all(promises);
    };

    private async _setPlotIndicatorPromise(indicator: any, indicatorsByTheme: any) {
        let polyToPoint = false;
        const indicatorId = indicator.indicatorId;
        const shape = indicator.shape;

        indicatorsByTheme.forEach((theme: Theme) =>
            theme.ss_theme.forEach((subTheme) => {
                const isAnyIndicatorPolyToPoint = subTheme.indicators.some(
                    (indicator) =>
                        indicator.id_indicateur == indicatorId &&
                        indicator.form == 'poly' &&
                        shape.includes('point'),
                );
                polyToPoint = polyToPoint || !!isAnyIndicatorPolyToPoint;
            }),
        );
        try {
            await this.dataService.getIndicator(indicatorId);
            await this._plotIndicator(indicator);
            const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
            this.plotIndicatorService.broadcastEvent(indicatorPlot);
            return;
        } catch (error) {
            console.error('Error loadScenario', error);
            throw error;
        }
    }

    private async _plotIndicator(indicator: any) {
        const indicatorId = indicator.indicatorId;
        const indicatorData = this.dataService.activeIndicators[indicatorId];
        const granularity = this.terService.territoryScales.find(
            (territoryScale) => territoryScale.type == indicator.granularityName,
        );

        let colorId: number;
        let gradientColorName = indicator.gradientColorName;

        if (!gradientColorName) {
            const degradeInfo = this.colorService.colorCatalog.find(
                (color) => color.id == indicator.colorId,
            );
            gradientColorName = degradeInfo.name;
            colorId = degradeInfo.id;
        }

        let distributionMethodId: string;
        let legendBoundaries = undefined;
        try {
            const tableBornes = JSON.parse(indicator.distributionMethodId);
            const decimalCount = indicatorData.decimal_count;
            legendBoundaries = this.manualDistribution.convertTableBornesToLegendBoundaries(
                tableBornes,
                decimalCount,
            );
            distributionMethodId = indicator.distributionMethodId;
        } catch {
            distributionMethodId = indicator.distributionMethodId;
        }
        const parameters = {
            classCount: indicator.classCount,
            distributionMethodId: distributionMethodId,
            legendBoundaries: legendBoundaries,
            indicatorfillopacity: indicator.opacity,
            degrade: gradientColorName,
            degradeId: colorId,
        };

        const refreshIntervals = true;
        const broadcastEvent = false;
        try {
            await this.plotIndicatorService.addIndicator(
                indicatorId,
                granularity,
                refreshIntervals,
                broadcastEvent,
                parameters,
            );
            await this._manageFilters(indicator);
        } catch (error) {
            console.error('Error plotIndicator', error);
            throw error;
        }
    }

    private async _manageFilters(indicator: any) {
        const indicatorId = indicator.indicatorId;
        const filtersInfo = indicator.filters || [];

        const filtersInfoConverted = filtersInfo.map((info: any) => ({
            indicatorId: info.indicatorId,
            id_critere: info.criteriaId,
            id_dter: info.determinantId,
        }));

        indicator.filters = filtersInfoConverted;

        const indicatorData = this.dataService.activeIndicators[indicatorId];
        const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
        const isEmpty = !indicatorPlot.hasOwnProperty('dataToPlot');
        const isFilterable = indicatorPlot.static_dynamic == 'dyn';

        if (!isEmpty && isFilterable) {
            try {
                await this.filterDataService.init(indicatorPlot, filtersInfoConverted);

                const filtersCreated = this.filterDataService.createFiltersArray(indicatorId);
                const isFiltered = filtersCreated.length > 0;
                indicatorData.isFiltered = isFiltered;
                indicatorPlot.isFiltered = isFiltered;

                const refreshIntervals = false;
                const broadcastEvent = false;
                await this.plotIndicatorService.updateIndicator(
                    indicatorId,
                    refreshIntervals,
                    broadcastEvent,
                );
            } catch (error) {
                console.error('Error _manageFilters', error);
            }
        }
    }

    private _loadPlugins(plugins: any[]) {
        plugins.forEach((pluginInfo) => {
            const pluginName = pluginInfo.pluginName;
            if (pluginName == 'prosper_reseaux') {
                const data = pluginInfo.info;
                this.prClearService.clear();
                this.prService.loadSavedScenario(data);
            }
        });
    }

    async delete(scenarioId: number) {
        try {
            await this.restService.deleteScenario(scenarioId);
            this.scenarios = await this.restService.getScenarios();
            if (this.currentScenario.id == scenarioId) {
                this.currentScenario = {
                    id: -1,
                    name: '',
                    rightId: 1,
                    creatorUserId: null,
                    isLoading: false,
                    isLoaded: false,
                    raster: null,
                    territory: null,
                    indicators: null,
                    filters: null,
                    plugins: null,
                };
            }
            return this.scenarios;
        } catch (error) {
            console.error('Error delete', error);
            throw error;
        }
    }
}
