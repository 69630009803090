<nav class="navbar navbar-expand-lg bg-st-base02 main-navbar py-2" id="header-siterre">
    <div class="container-fluid bg-st-base02">
        <div class="navbar-header py-0">
            <a (click)="goToMain()" class="navbar-brand d-flex align-items-center">
                <img
                    [src]="'../../../assets/img/header/' + siterreLogo"
                    alt="siterre-logo"
                    height="45"
                    class="d-inline-block align-text-top align-text-top"
                    [ngClass]="{'cursor-pointer': isLogged()}"
                />
                <h5 class="ms-5 mb-0 text-st-secondary text-wrap" *ngIf="siteName">
                    <b>{{ siteName }}</b>
                </h5>
            </a>
        </div>

        <button
            class="navbar-toggler"
            type="button"
            (click)="isCollapsed = !isCollapsed"
            data-bs-target="#siterre-navbar-collapse"
            aria-controls="siterre-navbar-collapse"
            [attr.aria-expanded]="!isCollapsed"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>

        <div
            id="siterre-navbar-collapse"
            class="collapse navbar-collapse"
            [ngbCollapse]="isCollapsed"
        >
            <ul
                id="territory-selection"
                class="nav navbar-nav ms-auto"
                [ngClass]="{'absolute-center': !siteName}"
                *ngIf="isInitialized"
            >
                <li
                    [ngClass]="{'pseudo-center': !siteName, 'admin-accessed': hasAdminButton && isAdmin, 'scenarios-accessed': isScenariosAvailable}"
                >
                    <button
                        id="territory-selection-toggle"
                        type="button"
                        class="btn text-st-white truncate"
                        [ngClass]="{'btn-st-active': isTerritorySelectionModalOpen, 'btn-st-primary': !isTerritorySelectionModalOpen}"
                        (click)="openTerritorySelectionModal()"
                    >
                        {{ territories.length > 1 ? 'Territoires' : 'Territoire'}} : {{
                        territories.length > 2 ? territories.length + ' sélectionnés' :
                        territories.join(' & ') }}
                    </button>
                </li>
            </ul>

            <ul class="nav navbar-nav ms-auto">
                <li class="header-link d-flex align-items-center header-logo" *ngIf="logo">
                    <img src="{{ logo }}" alt="client-logo" height="45" class="px-3" />
                </li>
                <li class="header-link d-flex align-items-center">
                    <a
                        href="https://energies-demain.com/"
                        target="_blank"
                        class="header-logo ed-logo-link px-3"
                    >
                        <img
                            src="../../../assets/img/header/logo_ed.png"
                            alt="energies-demain-logo"
                            height="45"
                        />
                    </a>
                </li>
                <li
                    class="header-link p-3"
                    *ngIf="isLogged() && isInitialized && isScenariosAvailable"
                >
                    <a
                        class="btn-link"
                        (click)="openScenarioModal()"
                        title="Enregistrer ou charger une étude"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                            <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                            />
                        </svg>
                    </a>
                </li>
                <li *ngIf="hasAdminButton && isAdmin" class="header-link p-3">
                    <a class="btn-link" (click)="goToAdmin()" title="Interface d'administration">
                        <i class="fa fa-users fa-lg"></i>
                    </a>
                </li>
                <li class="header-link p-3" *ngIf="isLogged()">
                    <a class="btn-link" (click)="logOut()" title="Déconnexion">
                        <i class="fa fa-power-off fa-lg"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
