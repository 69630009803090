('use strict');

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UsefulService {
    showMobileUi = false;

    constructor() {
        this.checkIfMobile();
    }

    encodeForUrl(data: any, ignoreNull?: boolean) {
        let stringToEncode = '';

        const encodeObjectArray = function (nestedData: any, separator = ' OR ') {
            if (!nestedData) return undefined;

            let result = '';

            for (const nestedParameter in nestedData) {
                result += nestedData[nestedParameter]
                    .map((elem: any) => {
                        return `${nestedParameter}='${elem}'`;
                    })
                    .join(separator);
            }
            return `[${result}]`;
        };

        for (const parameter in data) {
            if (!ignoreNull || (data[parameter] !== null && data[parameter] !== '')) {
                if (stringToEncode !== '') {
                    stringToEncode += '&';
                }
                if (typeof data[parameter] === 'object') {
                    stringToEncode +=
                        encodeURIComponent(parameter) +
                        '=' +
                        encodeURIComponent(encodeObjectArray(data[parameter]));
                } else {
                    stringToEncode +=
                        encodeURIComponent(parameter) + '=' + encodeURIComponent(data[parameter]);
                }
            }
        }
        return stringToEncode;
    }

    round(value: number, decimalCount: number = 0): number {
        const roundedValue =
            Math.round(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);

        return roundedValue;
    }

    floor(value: number, decimalCount: number = 0): number {
        const flooredValue =
            Math.floor(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);

        return flooredValue;
    }

    ceil(value: number, decimalCount: number = 0) {
        const ceiledValue =
            Math.ceil(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);

        return ceiledValue;
    }

    stringifyNumber(value: number, addSpacing: boolean = true) {
        let stringifiedValue = value.toString().replace('.', ',');

        // if (decimalCount > 0) {
        //     let decimalPart = stringifiedValue.slice(stringifiedValue.indexOf(',') + 1);
        //     if (decimalCount > decimalPart.length) {
        //         decimalPart = decimalPart + '0'.repeat(decimalCount - decimalPart.length);
        //     }
        // }

        if (addSpacing) {
            stringifiedValue = stringifiedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
        return stringifiedValue;
    }

    sortAlphabetically(arr: any[], key: string) {
        arr.sort((a, b) => {
            const A = a[key].toUpperCase();
            const B = b[key].toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return arr;
    }

    removeDuplicateObjects(arr: any[]) {
        const uniqueObjects = new Set();
        const result = [];

        for (const obj of arr) {
            const objStr = JSON.stringify(obj);
            if (!uniqueObjects.has(objStr)) {
                uniqueObjects.add(objStr);
                result.push(obj);
            }
        }

        return result;
    }

    checkIfMobile() {
        // Detect mobile devices via the user agent, regex found on http://detectmobilebrowsers.com/
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
        const firstRegex =
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
        const secondRegex =
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;

        const isMobile = firstRegex.test(userAgent) || secondRegex.test(userAgent.substr(0, 4));
        this.showMobileUi = isMobile && environment.hasMobileUi;

        if (this.showMobileUi) {
            document.documentElement.setAttribute('data-ui', 'mobile');
        }
    }

    countFrequencyByValue(arr: any[]) {
        const frequencyByValue = arr.reduce((map: any, value: any) => {
            map[value] = (map[value] || 0) + 1;
            return map;
        }, {});
        return frequencyByValue;
    }

    getTopMostFrequent(uniqueClassIds: number[], frequencyByClassId: any, size?: number) {
        const topMostFrequent = uniqueClassIds.sort(
            (a, b) => frequencyByClassId[b] - frequencyByClassId[a],
        );
        if (size) {
            return topMostFrequent.slice(0, size);
        }
        return topMostFrequent;
    }

    getTopLessFrequent(uniqueClassIds: number[], frequencyByClassId: any, size?: number) {
        const topLessFrequent = uniqueClassIds.sort(
            (a, b) => frequencyByClassId[a] - frequencyByClassId[b],
        );
        if (size) {
            return topLessFrequent.slice(0, size);
        }
        return topLessFrequent;
    }

    async wait(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
