import { Inject, Injectable } from '@angular/core';
import * as d3 from 'd3';

import { Indicator } from 'src/app/models/Indicator';

import { UsefulService } from 'src/app/services/UsefulService';

@Injectable({
    providedIn: 'root',
})
export class LinearDistribution {
    public classCountsAvailable = [2, 3, 4, 5, 6, 7, 8, 9];

    public id = 'linéaire';
    public label = 'Linéaire';

    constructor(@Inject(UsefulService) private usefulService: UsefulService) {}

    setClassCount(indicatorPlot: Indicator) {
        const distinctValues = indicatorPlot.distinctValues;

        let classCount: number;
        if (indicatorPlot.form == 'chart') {
            classCount = Object.keys(JSON.parse(indicatorPlot.chart_divider)).length;
        } else {
            const classCounts = [distinctValues.length];

            if (indicatorPlot.classCount > 1) {
                classCounts.push(indicatorPlot.classCount);
            }

            if (indicatorPlot.classCountsAvailable.length) {
                classCounts.push(Math.max(...indicatorPlot.classCountsAvailable));
            }
            classCount = Math.min(...classCounts);
        }
        indicatorPlot.classCount = classCount;
    }

    setScale(indicatorPlot: Indicator) {
        let values = indicatorPlot.values;
        if (indicatorPlot.separate_zero_in_lgd) {
            values = values.filter((value) => value != 0);
        }

        indicatorPlot.valueScale = d3
            .scaleQuantize()
            .domain(d3.extent(values))
            .range(d3.range(indicatorPlot.classCount));
    }

    setLegendBoundaries(indicatorPlot: Indicator) {
        const decimalCount = indicatorPlot.decimalCount;

        const legendBoundaries = [];
        const step = 1 / Math.pow(10, decimalCount);

        for (let i = 0; i < indicatorPlot.classCount; i++) {
            const limits = [...indicatorPlot.valueScale.invertExtent(i)];

            let boundaryInf: number;
            let boundarySup: number;

            if (i == 0) {
                boundaryInf = this.usefulService.floor(limits[0], decimalCount);
                boundarySup = this.usefulService.round(limits[1], decimalCount);
            } else if (i == indicatorPlot.classCount - 1) {
                const valueInf = this.usefulService.round(limits[0], decimalCount) + step;
                boundaryInf = this.usefulService.round(valueInf, decimalCount); // rounding to avoid precision error;
                boundarySup = this.usefulService.ceil(limits[1], decimalCount);
            } else {
                const valueInf = this.usefulService.round(limits[0], decimalCount) + step;
                boundaryInf = this.usefulService.round(valueInf, decimalCount); // rounding to avoid precision error;
                boundarySup = this.usefulService.round(limits[1], decimalCount);
            }
            const boundaries = [boundaryInf, boundarySup];
            legendBoundaries.push(boundaries);
        }
        return legendBoundaries;
    }
}
