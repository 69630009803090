import { Component, EventEmitter, Input, Output, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'stSelect',
    templateUrl: './st-select.template.html',
    providers: [],
})
export class StSelectComponent implements ControlValueAccessor {
    @Input() valueField: string = 'id';
    @Input() labelField: string = 'label';
    @Input() innerId: string;
    @Input() set data(value: any) {
        this.orderList(value);
        this.formatList();
    }
    @Input() innerClass: string;
    @Input() placeholder: string;
    @Input() size: number;
    @Input() style: string;
    @Input() orderBy: string;
    @Input() isDisabled: boolean;
    @Input() set type(value: string) {
        this.setClassByType(value);
    }
    @Output() onInit = new EventEmitter();

    onChange: any = (_: any) => {};
    onTouch: any = (_: any) => {};
    private _selectedValue: any;
    list: any[] = [];
    class: string;

    public get control(): AbstractControl | null {
        return this.ngControl ? this.ngControl.control : null;
    }

    constructor(@Self() @Optional() private ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    orderList(data: any) {
        this.list = Array.isArray(data) ? data : Object.values(data);
        if (data && this.orderBy) {
            this.list.sort((a, b) => {
                if (typeof a[this.orderBy] === 'string') {
                    return a[this.orderBy].localeCompare(b[this.orderBy]);
                }
                return a[this.orderBy] - b[this.orderBy];
            });
        }
    }

    formatList() {
        this.list = this.list.map((item: any) => {
            return {
                label: this.getItemLabel(item),
                value: this.getItemValue(item),
                class: this.getItemClass(item),
            };
        });
    }

    getItemValue(item: any) {
        if (this.valueField === 'fullObject') {
            return item;
        }
        return item && item[this.valueField];
    }

    getItemLabel(item: any) {
        if (this.labelField === 'fullObject') {
            return item;
        }
        const fields = this.labelField.split('.');
        if (fields.length > 1) {
            return item[fields[0]][fields[1]];
        } else {
            return item[this.labelField];
        }
    }

    getItemClass(item: any) {
        if (this.valueField !== 'fullObject') {
            return item.class;
        }
        return;
    }

    setClassByType(type: string) {
        this.class = type === 'primary' ? 'form-control primary' : 'form-control';
    }

    set selectedValue(val: any) {
        if (val !== undefined && this._selectedValue !== val) {
            this._selectedValue = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    get selectedValue() {
        return this._selectedValue;
    }

    writeValue(value: any) {
        this._selectedValue = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
