<select
    [id]="innerId"
    [(ngModel)]="selectedValue"
    class="form-select {{ class }}"
    [ngClass]="{disabled: isDisabled}"
    [disabled]="isDisabled"
>
    <option
        *ngIf="(placeholder && !list.length) || (placeholder && selectedValue)"
        [value]="null"
        [disabled]="true"
        [selected]
        class="placeholder"
    >
        {{ placeholder }}
    </option>
    <option *ngFor="let item of list" [ngValue]="item.value" [class]="item.class">
        {{ item.label }}
    </option>
</select>
